import React, { useState ,useEffect } from "react"
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { isbot } from "isbot";
import "./Content.scss"

const ContentBlock = (props) => {
  const [readMore, setReadMore] = useState(props.Content.length > 450 ? true : false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [userAgent, setUserAgent] = useState(null);

    const toggleDescContent = () => {
        setShowFullContent(!showFullContent);
        toggleReadMore();
    };
  const toggleReadMore = () => {
      setReadMore(!readMore);
    };
    useEffect(() =>{
      if(window && userAgent == null){
          setUserAgent(window?.navigator.userAgent)
      }
  },[])

  return (
    <React.Fragment> 
      <div className={`article-section ${props.classNames}`}>
         <div className="animated">
          <div className="article-section-content">
            {props.Content_Heading_Text && <p className="highlight-text">{props.Content_Heading_Text}
            </p>}
            {/* {props.Content && HTMLReactParser(props.Content)} */}
            {props.Content &&
                <div className="animated">
                  <p className={` desc my-desc-content ${showFullContent || isbot(userAgent) === true ? 'full-content' : ''}`}>
                            <div dangerouslySetInnerHTML={{ __html: props.Content }} />
                         </p>
                         
                         <div className="desc">
                            {props.Content.length > 850 && 
                                    <span onClick={toggleDescContent} className="read-more-less">
                                    {showFullContent ||  isbot(userAgent) === true ? ' - Read Less' : ' + Read More'}
                                  </span> }
                          </div>
                      </div>
              } 
          </div>
         </div>
      </div>
    </React.Fragment>
  )
}

export default ContentBlock