export const locationDubai = () => {
  return [
    'Nad Al Sheba 1',
    'Mirdif',
    'The World Islands',
    'Sobha Hartland',
    'Azizi Riviera',
    'Damac lagoons',
    'Downtown Dubai',
    'Dubai Maritime City',
    'Burj Khalifa District',
    'Jumeirah Lakes Towers',
    'Jumeirah Village Circle',
    'Al Furjan',
    'Meydan District',
    'Dubai south',
    'Jumeirah Lake Towers',
    'Business Bay',
    'Jumeirah Village Triangle',
    'Dubailand',
    'Dubai Harbour',
    'Palm Jumeirah',
    'Mohammed Bin Rashid City',
    "One Za'abeel",
    'Al Safa Park',
  ]
}

export const locationForSaleQatar = () => {
  return [
    'Lusail',
    'Lusail City',
    'Al Wakrah',
    'Gewan Island',
    'Al Thumama',
    'West Bay',
    'The Pearl'
  ]
}

export const locationForRentQatar = () => {
  return [
    'Lusail City',
    'Al Waab',
    'West Bay',
    'Al Mamoura',
    'The Pearl',
    'Msheireb Downtown Doha'
  ]
}