import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Badge, Form } from 'react-bootstrap';
import HTMLParser from 'html-react-parser';

// Images
import Img1Large from "../../../images/static2/img1-desktop.jpg"
import Img1Medium from "../../../images/static2/img1-tablet.jpg"
import Img1Small from "../../../images/static2/img1-mobile.jpg"
import Img2Large from "../../../images/static2/img2-desktop.jpg"
import Img2Medium from "../../../images/static2/img2-tablet.jpg"
import Img2Small from "../../../images/static2/img2-mobile.jpg"

import Calendly from "../../../modules/calendly-link";
import { youtube_parser, numericWithDecimal } from "../../common/utils";

// Style
import './ProjectList.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import { ImageModule } from "../../../modules/Image_Module";
import SelectBox from "../../Home/SelectBox/SelectBox"

const ProjectList = (props) => {

  const [isPlay, setPlay] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [data, setData] = useState([]);
  const [youtubeId, setYoutubeId] = useState('');

  const openVideo = (youtube_id) => {
    setPlay(true);
    setYoutubeId(youtube_id);
  } 

 

  return (
    <div className="benefit-block project-list-block">
       <div className="animated">
       
          {props?.data && props?.data?.length > 0 ? (

            props?.data.map((item, index) => {

              var youtube_url = item.Youtube_Video_URL && youtube_parser(item.Youtube_Video_URL);

              let processedImages = JSON.stringify({});
              if (item.imagetransforms && item.imagetransforms.Title_Image_Transforms) {
                processedImages = item.imagetransforms.Title_Image_Transforms;
              }
              var price_start = item.Price_Starting_From && numericWithDecimal(item.Price_Starting_From);

              let slug = '/new-projects-in-dubai/'
              if(item.Region === "Qatar" && item.Property_Status === "Rent"){
                slug = '/rent-new-projects-in-qatar/'
              }else if(item.Region === "Qatar" && item.Property_Status === "Sale"){
                slug = '/buy-new-projects-in-qatar/'
              }

              return (
                <Row className="project-section-list" key={index}>
                  <Col lg="5">
                    <div className="benefit-block-img img-zoom">
                      <a href={ item.Region === 'Qatar' && youtube_url ? "javascript:void(0)" : `${slug}${item.URL}`}  onClick={item.Region === 'Qatar' && youtube_url ? () => openVideo(youtube_url) : null}>
                      {
                        item.Title_Image ?
                          <picture>
                               <img className="" src={item.Title_Image.url} alt="" /> 
                          </picture> : <span className="noImg"></span>
                      }
                       </a>

                      {item.Region === 'Qatar' && youtube_url  ? <strong  className="play-btn-circle" onClick = {e => openVideo(youtube_url)} ><i className="icon-play"></i></strong> : ''}
                     
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="benefit-block-content">

                      <h2>{item.Name}</h2>
                      {/* <p>{item.Short_Description}</p> */}
                      <ul className="project-info">
                        <li>
                          <strong>Project Type:</strong>
                          <span>{item.Project_Type}</span>
                        </li>
                        <li>
                          <strong>Category:</strong>
                          <span>{item.Category}</span>
                        </li>
                        <li>
                          <strong>Listing Status:</strong>
                          <span>{item.Listing_Status}</span>
                        </li>
                        <li>
                          <strong className="project-info-title">Price Range:</strong>
                          <span>{item.Price_Range}</span>  <Badge variant="secondary" className={item.Priority === "Medium" ? "warning" : item.Priority === "High" ? "danger" : item.Priority === "Low" ? "info" : "default"}>{item.Priority}</Badge>
                        </li>

                      </ul>
                      <Link to={`${slug}${item.URL}`} className="btn btn-primary">
                        <span>Find out more</span>
                      </Link>
                    </div>
                  </Col>
                </Row>
              )

            })

          ) : (
            props?.data && props?.data?.newProjects?.length > 0 ? (

              props.data.newProjects.map((item, index) => {

                var youtube_url = item.Youtube_Video_URL && youtube_parser(item.Youtube_Video_URL);
  
                let processedImages = JSON.stringify({});
                if (item.imagetransforms && item.imagetransforms.Title_Image_Transforms) {
                    processedImages = item.imagetransforms.Title_Image_Transforms;
                }
                var price_start = item.Price_Starting_From && numericWithDecimal(item.Price_Starting_From);
  
                return(
                  <Row className="project-section-list">
                    <Col lg="5">
                    <div className="benefit-block-img img-zoom">
                      {
                      item.Title_Image ?
                      <picture>
                        {/* <source media="(min-width:992px)" srcset={Img1Large} />
                        <source media="(min-width:768px)" srcset={Img1Medium} />
                        <img className="" src={Img1Small} alt="" /> */}
                        <ImageModule ImageSrc={item.Title_Image} imagename={"new-projects.Title_Image.tile_image"} article_id={item.id} imagetransforms={processedImages} />
                        </picture> : <span className="noImg"></span>
                      }
                      
                      {item.Region === 'Qatar'? <strong href="" className="play-btn-circle" onClick = { e => openVideo(youtube_url)}><i className="icon-play"></i></strong>: ''}
                    </div>
                    </Col>
                    <Col lg="7">
                    <div className="benefit-block-content">
                    
                    <h2>{item.Name}</h2>
                    {/* <p>{item.Short_Description}</p> */}
                    <ul className="project-info">
                      <li>
                        <strong>Project Type:</strong>
                        <span>{item.Project_Type}</span>
                      </li>
                      <li>
                        <strong>Category:</strong>
                        <span>{item.Category}</span>
                      </li>
                      <li>
                        <strong>Listing Status:</strong>
                        <span>{item.Listing_Status}</span>
                      </li>
                      <li>
                        <strong className="project-info-title">Price Range:</strong>
                        <span>{item.Price_Range}</span>  <Badge variant="secondary" className={item.Priority === "Medium" ? "warning" : item.Priority === "High" ? "danger" : item.Priority === "Low" ? "info" : "default"}>{item.Priority}</Badge>
                      </li>
                      
                    </ul>
                    <Link to={item.URL} className="btn btn-primary">
                      <span>Find out more</span>
                    </Link>
                    </div>
                    </Col>
                  </Row>
                )  
                
              }) 
              
            ) : (
              <span className="text-center col-12">No projects found.</span>
            )
          )
          }
      
       </div>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={youtubeId}
        isAutoPlay={1}
      />

    </div>
  )
}

export default ProjectList
