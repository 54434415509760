import React, { useEffect, useState } from "react"
import { Row, Col, Container, Breadcrumb, Form } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { navigate } from "@reach/router"
import moment from "moment"
import Select from 'react-select';
import { Helmet } from "react-helmet";
import HTMLReactParser from 'html-react-parser';
import SelectBox from "../Home/SelectBox/SelectBoxValue.js"
import { NewProjects, NewProjectPropertyTypes, NewProjectBedrooms } from "../../queries/common_use_query"
import { numericWithDecimal } from "../common/utils";
import ProjectList from "./ProjectList/ProjectList"
import SearchResultSchema from "../SearchResultSchema/SearchResultSchema";
import { locationDubai, locationForSaleQatar, locationForRentQatar } from "./Locations"
import SEO from "../seo";

const NewProjectsList = (props) => {
  //console.log("newprops",props)
  const [filter_area, setFilterArea] = useState('');
  const [title_area, setTitleArea] = useState('');
  const [filter_category, setFilterCategory] = useState('');
  const [filter_ptype, setFilterProType] = useState('');
  const [filter_property, setFilterProperty] = useState('');
  const [filter_property_type, setFilterPropertyType] = useState('');
  const [filter_price, setFilterPrice] = useState('');
  const [filter_bedroom, setFilterBedroom] = useState('');
  const [filter_completion, setFilterCompletion] = useState('');
  const [filtered_url, setFilterUrl] = useState('');
  const [projectData, setProjectData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [filter, setFilter] = useState(false);
  
  const { loading: initLoading, error: initError, data:initProjectData } = NewProjects(props.pstatus, props.region, props.sort, 5);
  const { loading, error, data } = NewProjects(props.pstatus, props.region, props.sort, 1000);

  const { loading: propTypeloading, error: propTypeerror, data: propTypeData } = NewProjectPropertyTypes();
    
  const { loading: bedroomsloading, error: bedroomserror, data: bedroomsData } = NewProjectBedrooms();

  var page_url = "/qatar-property-services/buy-property/view-for-our-latest-projects"

  let path_name = props.location.hash && (props.location.hash).replace('#', '');
  let area_name = path_name;
  let price_range = '';


  
  let areaList = [{ value: '', label: 'All Areas' }];


  if (props.Alias === "our-new-projects-in-dubai") {
    page_url = "/dubai-property-services/buy-property-in-dubai/new-projects-in-dubai"
  } else if (props.Alias === "new-projects-rent") {
    page_url = "/qatar-property-services/rent-a-property/view-for-our-latest-projects-rent"
  }

  const category = [{ value: '', label: 'All Categories' }];
  const projectType = [{ value: '', label: 'All Types' }];

  const propertyType = [
    { value: '', label: 'All Types ' },
  ];

  const bedrooms = [
    { value: '', label: 'Any' },
  ];
  const completion = [
    { value: '', label: 'All' },
  ];

  /** string remove all special characters*/
  const removeSpecialChar = (val, symbol = '-', lowerCase = true) => {
    if (val !== undefined && val !== '' && val !== null && typeof val !== 'number') {
      if (lowerCase) {
        return val.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(/ /gi, symbol).toLowerCase()
      }
      return val.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(/ /gi, symbol)
    }
    return val;
  }
 let locations = locationDubai();
  if (props.region === "Qatar") { 
    if (props.pstatus === "Sale") {
      locations = locationForSaleQatar();
    } else {
      locations = locationForRentQatar();
    } 
  }

  locations.map((loc) => {
    areaList.push({ value: removeSpecialChar(loc), label: loc })
  })

  if (props.region === 'Dubai') {
    price_range = [
      { value: '', label: 'Price Range' },
      { value: '0-500000', label: 'AED 0 - 500,000' },
      { value: '500000-1000000', label: 'AED 500,000 - 1,000,000' },
      { value: '1000000-1500000', label: 'AED 1,000,000 - 1,500,000' },
      { value: '1500000-2000000', label: 'AED 1,500,000 - 2,000,000' },
      { value: '2000000-2500000', label: 'AED 2,000,000 - 2,500,000' },
      { value: '2500000-3000000', label: 'AED 2,500,000 - 3,000,000' },
      { value: '3000000-3500000', label: 'AED 3,000,000 - 3,500,000' },
      { value: '3500000-4000000', label: 'AED 3,500,000 - 4,000,000' },
      { value: '4000000-10000000000', label: 'AED 4,000,000+' },
    ];


    if (props.pstatus === "Rent") {
      price_range = [
        { value: '', label: 'Price Range' },
        { value: '0-5000', label: 'AED 0 - 5,000' },
        { value: '5000-10000', label: 'AED 5,000 - 10,000' },
        { value: '10000-15000', label: 'AED 10,000 - 15,000' },
        { value: '15000-20000', label: 'AED 15,000 - 20,000' },
        { value: '20000-25000', label: 'AED 20,000 - 25,000' },
        { value: '25000-30000', label: 'AED 25,000 - 30,000' },
        { value: '30000-35000', label: 'AED 30,000 - 35,000' },
        { value: '35000-40000', label: 'AED 35,000 - 40,000' },
        { value: '40000-1000000', label: 'AED 40,000+' },
      ];
    }
  } else if (props.region === 'Qatar') {
    price_range = [
      { value: '', label: 'Price Range' },
      { value: '0-500000', label: 'QAR 0 - 500,000' },
      { value: '500000-1000000', label: 'QAR 500,000 - 1,000,000' },
      { value: '1000000-1500000', label: 'QAR 1,000,000 - 1,500,000' },
      { value: '1500000-2000000', label: 'QAR 1,500,000 - 2,000,000' },
      { value: '2000000-2500000', label: 'QAR 2,000,000 - 2,500,000' },
      { value: '2500000-3000000', label: 'QAR 2,500,000 - 3,000,000' },
      { value: '3000000-3500000', label: 'QAR 3,000,000 - 3,500,000' },
      { value: '3500000-4000000', label: 'QAR 3,500,000 - 4,000,000' },
      { value: '4000000-10000000000', label: 'QAR 4,000,000+' },
    ];

    if (props.pstatus === "Rent") {
      price_range = [
        { value: '', label: 'Price Range' },
        { value: '0-5000', label: 'QAR 0 - 5,000' },
        { value: '5000-10000', label: 'QAR 5,000 - 10,000' },
        { value: '10000-15000', label: 'QAR 10,000 - 15,000' },
        { value: '15000-20000', label: 'QAR 15,000 - 20,000' },
        { value: '20000-25000', label: 'QAR 20,000 - 25,000' },
        { value: '25000-30000', label: 'QAR 25,000 - 30,000' },
        { value: '30000-35000', label: 'QAR 30,000 - 35,000' },
        { value: '35000-40000', label: 'QAR 35,000 - 40,000' },
        { value: '40000-1000000', label: 'QAR 40,000+' },
      ];
    }
  } 
  if (!propTypeloading && !propTypeerror && propTypeData && propTypeData.newProjectPropertyTypes) {
    propTypeData.newProjectPropertyTypes.forEach(item => {
      propertyType.push({ value: removeSpecialChar(item.Type), label: item.Type });
    });
  }
  
if (!bedroomsloading && !bedroomserror && bedroomsData && bedroomsData.newProjectBedrooms) {
    const sortedBedrooms = bedroomsData.newProjectBedrooms
      .map(item => ({ value: item.Bedroom.toString(), label: (item.Bedroom === "0" ? "Studio" : item.Bedroom) }))
      .sort((a, b) => {
        const bedroomA = parseInt(a.value, 10);
        const bedroomB = parseInt(b.value, 10);
  
        if (bedroomA === 0) {
          return -1; // "Studio" (0) comes before other numbers
        } else if (bedroomB === 0) {
          return 1; // "Studio" (0) comes before other numbers
        } else {
          return bedroomA - bedroomB; // Sort other numbers in ascending order
        }
      });
  
    bedrooms.push(...sortedBedrooms);
  }
  
  data && data.newProjects.length > 0 && data.newProjects.map((item, index) => {
    // if (props.region === 'Dubai') {
    //   areaType.push({ value: removeSpecialChar(item.Name), label: item.Name });
    // }

    category.push({ value: removeSpecialChar(item.Category), label: item.Category });
    projectType.push({ value: removeSpecialChar(item.Project_Type), label: item.Project_Type });
    
    if (item.Completion_Date) {
      completion.push({ value: moment(item.Completion_Date).format('YYYY'), label: moment(item.Completion_Date).format('YYYY') });
    }
  })
  
  const key = 'value';

  var unique_cat = [...new Map(category.map(item => [item[key], item])).values()];
  var unique_proType = [...new Map(projectType.map(item => [item[key], item])).values()];
  var unique_property = [...new Map(propertyType.map(item => [item[key], item])).values()];
  var unique_bedrooms = [...new Map(bedrooms.map(item => [item[key], item])).values()];
  var unique_completion = [...new Map(completion.map(item => [item[key], item])).values()];

  const areaName = (val, decode = true) => {
    let value = val;
    let areas = areaList.find((element) => {
      return element.value === val;
    })

    if (areas && areas?.value) {
      value = areas.label;
    }
    if (decode) {
      return removeSpecialChar(value, ' ', false)
    }
    return removeSpecialChar(value, '-', false)
  }
  const getUrlParams = () => {
    let location = window.location;
    let pathUri_main = location.pathname.split(page_url)
    let filt_area, filt_category, filt_ptype, filt_price, filt_bedroom, filt_completion, filt_property = null;
        if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")
      //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }
        // area
        if (pathUri[vi].indexOf("area-") == 0) {
          filt_area = (pathUri[vi].replace("area-", ""))
        }
        // category
        if (pathUri[vi].indexOf("category-") == 0) {
          filt_category = (pathUri[vi].replace("category-", ""))
        }
        // project
        if (pathUri[vi].indexOf("project-") == 0) {
          filt_ptype = (pathUri[vi].replace("project-", ""))
        }
        // price
        if (pathUri[vi].indexOf("price-") == 0) {
          filt_price = (pathUri[vi].replace("price-", ""))
        }
        // bedroom
        if (pathUri[vi].indexOf("bedroom-") == 0) {
          filt_bedroom = (pathUri[vi].replace("bedroom-", ""))
        }
        if (pathUri[vi].indexOf("studio") == 0) {
            filt_bedroom = "0";
        }
        // completion
        if (pathUri[vi].indexOf("completion-") == 0) {
          filt_completion = (pathUri[vi].replace("completion-", ""))
        }
        // property
        if (pathUri[vi].indexOf("property-") == 0) {
          filt_property = (pathUri[vi].replace("property-", ""))
        }
      }
    }
    return { filt_area, filt_category, filt_ptype, filt_price, filt_bedroom, filt_completion, filt_property };
  }

  useEffect(() => {
    area_name && setFilterArea(area_name.replace(/-/g, ' '))
  }, [area_name])

  useEffect(() => {
    if (typeof document !== "undefined") {
      const dynamicArea = document.getElementById("area");
      if (dynamicArea) {
        dynamicArea.innerHTML = filter_area ? areaName(filter_area) : 'Qatar'
      }
    }
  }, [filter_area])

  useEffect(() => {
    if (data) {
      let { filt_area, filt_category, filt_ptype, filt_price, filt_bedroom, filt_completion, filt_property } = getUrlParams();

      var filteredData = data?.newProjects;
      setFilterArea(areaName(filt_area));
      setTitleArea(areaName(filt_area))
      setFilterPropertyType(filt_property)
      if (filt_area || filt_category || filt_ptype || filt_price || filt_bedroom || filt_completion || filt_property) {
        if (filt_area) {
          filteredData = filteredData.filter(project => {
            let areaVal = new RegExp(filt_area, 'gi');
            let city = project.City ? project.City : "null";
            let Project_Type = project.Project_Type ? project.Project_Type : "null";
            let Developer = project.Developer ? project.Developer : "null";

            return (removeSpecialChar(project.Name).match(areaVal) || removeSpecialChar(Developer).match(areaVal) || removeSpecialChar(Project_Type).match(areaVal) || removeSpecialChar(city).match(areaVal) || removeSpecialChar(project.Region).match(areaVal))
          });
        }
        if (filt_ptype) {
          filteredData = filterQuery(filteredData, 'Project_Type', filt_ptype)
          setFilterProType(filt_ptype);
        }
        if (filt_category) {
          filteredData = filterQuery(filteredData, 'Category', filt_category);
          setFilterCategory(filt_category);
        }
              
        if (filt_bedroom && filt_property) {
            filteredData = filterProjects(filteredData, filt_bedroom, filt_property);
            setFilterBedroom(filt_bedroom);
            setFilterProperty(filt_property);
        }else if (filt_property) { 
            filteredData = filterProjects(filteredData, null, filt_property);
            setFilterProperty(filt_property);
        }else if (filt_bedroom) {
            filteredData = filterProjects(filteredData, filt_bedroom, null);
            setFilterBedroom(filt_bedroom); 
        }

        if (filt_price) {
          var split_price = filt_price && filt_price.split('-');
          if (split_price && split_price.length > 0) {
            filteredData = filteredData.filter(item => parseInt(numericWithDecimal(item.Price_Starting_From)) >= parseInt(split_price[0]) && parseInt(numericWithDecimal(item.Price_Starting_From)) < parseInt(split_price[1]));
            setFilterPrice(filt_price);
          }
        }
        if (filt_completion) {
          filteredData = filteredData.filter(item => moment(item.Completion_Date).format('YYYY') === moment(filt_completion).format('YYYY'));
          setFilterCompletion(filt_completion);
        }
      }

      setProjectData(filteredData);
    }else if(initProjectData){ 
      setProjectData(initProjectData?.newProjects);
    }  
  }, [data, initProjectData, filtered_url])

  let titlePrefix = "New Developments"
  let titleMetaPrefix = "Off Plan Properties"
  let actionIn = "in"
  if (props.region === "Qatar") {
    titlePrefix = "New Projects"
    titleMetaPrefix = "New Projects"
    if (props.pstatus === "Sale") {
      actionIn = "for Sale in"
    } else {
      actionIn = "for Rent in"
    }

  }

  let strTitle = props.Meta_Title;
  let strDesc = props.Meta_Description;
  let h1Title = `${titlePrefix} ${actionIn} ${props.region}`;
  let metaDescription;
  let metatitle = `${titleMetaPrefix} ${actionIn} ${props.region}`; 

  let setPrefix = "Off Plan";
  if (props.region === "Qatar") {
    setPrefix = "New"
  }
let h1propType ="";
  if (title_area && filter_property_type) {
    h1propType = filter_property_type.replace(/-/g, ' ');;
    h1Title = `${setPrefix} ${h1propType} ${actionIn} ${areaName(title_area)}, ${props.region}`;
    metatitle = `${h1Title}`
  } else if (filter_property_type) {
    h1propType = filter_property_type.replace(/-/g, ' ');;
    h1Title = `${setPrefix} ${h1propType} ${actionIn} ${props.region}`;
    metatitle = `${h1Title}`
  } else if (title_area) {
    setPrefix = "Off Plan Properties"
    if (props.region === "Qatar") {
      setPrefix = "New Projects"
    }
    h1Title = `${setPrefix} ${actionIn} ${areaName(title_area)}, ${props.region}`;
    metatitle = `${h1Title}`
  }

  if (props.region === "Dubai" && metatitle) {
    metatitle = `${metatitle} | ${metatitle.replace('Off Plan Properties', 'New Developments').replace('Off Plan', 'New Developments')}`
  }

  //console.log("met--title----->", h1Title)


  if (typeof strDesc === 'string' && props.pstatus === "Sale") {
    if (title_area) {
      metaDescription = strDesc.slice(0, 37) + areaName(title_area) + strDesc.slice(42);
    } else {
      metaDescription = strDesc.slice(0, 37) + 'Qatar' + strDesc.slice(42);
    }
    //console.log("desc==sale", metaDescription)
  } else if (typeof strDesc === 'string' && props.pstatus === "Rent") {
    if (title_area) {
      metaDescription = strDesc.slice(0, 41) + areaName(title_area) + strDesc.slice(46);
    } else {
      metaDescription = strDesc.slice(0, 41) + 'Qatar' + strDesc.slice(46);
    }
    //console.log("desc==rent", metaDescription)
  }

  const filterQuery = (filteredData, field, filter) => {
    const filterd = filteredData.filter(project => {
      return removeSpecialChar(project[field]) == filter;
    });
    return filterd;
  }
  
const filterProjects = (filteredData, bedroomCount, propertyType) => {
        return filteredData.filter((project) => {
          // Filter by property type and matching bedroom count
          if (propertyType !== null && bedroomCount !== null) {
            const matchingPropertyType = project.PropertyTypeAndBedroom.some((propertyTypeItem) =>
            propertyTypeItem?.Type && removeSpecialChar(propertyTypeItem.Type?.Type).toLowerCase() === propertyType.toLowerCase() &&
            propertyTypeItem?.bedrooms && propertyTypeItem.bedrooms.some((bedroom) => bedroom.Bedroom === bedroomCount)
            );
            return matchingPropertyType;
          }
          
          // Filter by property type only
          if (propertyType !== null) {
            const matchingPropertyType = project.PropertyTypeAndBedroom.some((propertyTypeItem) =>
            propertyTypeItem?.Type && removeSpecialChar(propertyTypeItem.Type?.Type).toLowerCase() === propertyType.toLowerCase()
            );
            return matchingPropertyType;
          }
      
          // Filter by bedroom count only
          if (bedroomCount !== null) {
            const matchingBedroom = project.PropertyTypeAndBedroom.some((propertyTypeItem) =>
            propertyTypeItem?.bedrooms && propertyTypeItem.bedrooms.some((bedroom) => bedroom.Bedroom === bedroomCount)
            );
            return matchingBedroom;
          }
          return true;
        });
      
}
  
  const submitFilter = () => {
    let filter_url = page_url
    // console.log('filter_data', filter_property, filter_bedroom)
    if (filter_area || filter_category || filter_ptype || filter_price || filter_bedroom || filter_completion || filter_property) {
      if (filter_area) {
        filter_url = `${filter_url}/area-${areaName(filter_area, false)}`
      }
      if (filter_ptype) {
        filter_url = `${filter_url}/project-${filter_ptype}`
      }
      if (filter_category) {
        filter_url = `${filter_url}/category-${filter_category}`
      }
      if (filter_bedroom) {
        if (filter_bedroom === "0") {
            filter_url = `${filter_url}/studio`
        }else {
            filter_url = `${filter_url}/bedroom-${filter_bedroom}`
        }
     }
      if (filter_property) {
        filter_url = `${filter_url}/property-${filter_property}`
      }
      if (filter_price) {
        filter_url = `${filter_url}/price-${filter_price}`
      }
      if (filter_completion) {
        filter_url = `${filter_url}/completion-${filter_completion}`
      }
    }
    setFilter(false)
    setFilterUrl(filter_url);
    navigate(filter_url)
  }
  const showInfoUpdate = () => {
    setShowInfo(!showInfo)
  }


  const showFilter = () => {
    setFilter(!filter)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0)
    })
  }, [])

 var lowpriceval = ""
 var highpriceval = ""
 if(props.pstatus){
  if(props.pstatus == "Sale" && props.region == "Qatar" || props.region == "Dubai"){
    lowpriceval = 500000
    highpriceval = 4000000
  }else if(props.pstatus == "Rent" && props.region == "Qatar"){
    lowpriceval = 5000
    highpriceval = 40000
  }
 }
 let schemaAggregateRating = true;
 if(props.pstatus == "Sale" && props.region == "Dubai") {
    schemaAggregateRating = false;
 }
 return (
    <React.Fragment>
      <SEO
        title={`${metatitle}`}
        description={metaDescription}
      /> 
      <SearchResultSchema title={metatitle} schemadesc={metaDescription} lowPrice={lowpriceval} highPrice={highpriceval} page="newprojectsearch" schemaAggregateRating ={schemaAggregateRating}/>
      <div className={scroll ? "active-scroll result-filter" : "result-filter"}>
        <h6 className={filter ? "active-result d-flex d-xl-none justify-content-between align-items-center" : "d-flex d-xl-none justify-content-between align-items-center"} onClick={e => showFilter()}><span>Filter Results</span> <i className="arrow-icon"></i></h6>
      </div>
      <div className={`filter-area-dropdown new_projects ${filter ? "d-block" : " d-none d-xl-flex "}`}>
        <Form.Group className="prop-loc property-search-area filter_box area">
          {/* <Form.Label>Property Location</Form.Label> */}
          <div className="select-group list-page">
            <Form.Label className="d-none d-xl-flex">Area</Form.Label>
            <Form.Control type="text" className="postcode_input form-control search_text" placeholder={`Location, Developer/Project`}
              onChange={(e) => setFilterArea(e.target.value)}
              value={areaName(filter_area)}
            />

            <Select
              className="postcode_input form-control select-group search_area_selct"
              name="search_area"
              // placeholder={`Location, Developer/Project`}
              options={areaList}
              classNamePrefix={"input-select-opt"}
              onChange={(e) => setFilterArea(e.value)}
              isSearchable={false}
            />
          </div>
        </Form.Group>
        <Form.Group className="filter_box">
          <Form.Label className="d-none d-xl-flex">Price</Form.Label>
          <SelectBox
            placeHolder="Price Range"
            options={price_range}
            handleChange={(e) => setFilterPrice(e.value)}
            value={filter_price}
            className={'full-block'}
          />
        </Form.Group>
        <Form.Group className="filter_box completion">
          <Form.Label className="d-none d-xl-flex">Min Bedrooms</Form.Label>
          <SelectBox
            placeHolder="Any"
            options={unique_bedrooms}
            handleChange={(e) => setFilterBedroom(e.value)}
            value={filter_bedroom}
          />
        </Form.Group >
        <Form.Group className="filter_box completion">
          <Form.Label className="d-none d-xl-flex">Completion</Form.Label>
          <SelectBox
            placeHolder="All"
            options={unique_completion}
            handleChange={(e) => setFilterCompletion(e.value)}
            value={filter_completion}
          />
        </Form.Group>
        <Form.Group className="filter_box">
          <Form.Label className="d-none d-xl-flex">Property Type</Form.Label>
          <SelectBox
            placeHolder="All Types"
            options={unique_property}
            handleChange={(e) => setFilterProperty(e.value)}
            value={filter_property}

          />
        </Form.Group>
        <Form.Group className="filter_box completion">
          <Form.Label className="d-none d-xl-flex">Project Type</Form.Label>
          <SelectBox
            placeHolder="All Types"
            options={unique_proType}
            handleChange={(e) => setFilterProType(e.value)}
            value={filter_ptype}
            className={'full-block'}
          />
        </Form.Group>
        <Form.Group className="filter_box">
          <Form.Label className="d-none d-xl-flex">Category</Form.Label>
          <SelectBox
            placeHolder="All Categories"
            options={unique_cat}
            handleChange={(e) => setFilterCategory(e.value)}
            value={filter_category}
          />
        </Form.Group>
        <Form.Group className="filter_box submit">
          <Form.Label></Form.Label>
          <button onClick={submitFilter} className="grey_nobg_btn">Update</button>
        </Form.Group>
      </div>
      <div className="container new_projects_list">
        <div className="new_project_cnt">
          <div className={`filter-block insights full_wdth  filter-list`}>
            <Row>
              <Col xl={12} lg={12} md={12} className="full_width_filter_block">
                <div className="filter-block-title ">
                   <div className="animated">
                    <h1>{h1Title}  ({!loading && projectData?.length})<a href="javascript:void(0)" onClick={showInfoUpdate}><i className="info-icon"></i></a></h1>
                    {showInfo &&
                      <div className="desc">
                          <p>{props?.Description && HTMLReactParser(props?.Description)}</p>
                      </div>
                    }
                   </div>
                </div>
              </Col>
            </Row>
          </div>
          {
            projectData && !initLoading &&
              <ProjectList data={projectData} filter_price={filter_price} page_url={page_url} />
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewProjectsList
